import { type FC } from "react";

import notification from "../../../assets/icons/notifications.svg";

export const HeaderRightSection: FC = () => {
  return (
    <div className="mf-height-100">
      <button className="mf-btn-img mf-height-100">
        <img
          src={notification}
          className="mf-cursor-pointer mf-mb-10"
          alt="notification"
        />
      </button>
    </div>
  );
};

export default HeaderRightSection;
