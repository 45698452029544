import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import LandingScreen from "../../shared/components/LandingScreen/LandingScreen";

export const SessionSharing = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("tkn");
    const redirectTo = searchParams.get("redirectTo");
    // removing the old access token if exist.
    localStorage.removeItem("access_tokens");
    if (token) {
      const session = window.atob(token);
      localStorage.setItem("access_tokens", session);
      if (redirectTo) {
        setSearchParams([]);
        navigate(`/${redirectTo}`);
        window.location.reload();
      }
    }
  }, [searchParams]);

  return (
    <LandingScreen>
      <div className="mf-loader" data-testid="session-sharing-loader"></div>
    </LandingScreen>
  );
};

export default SessionSharing;
