import React, { useId, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  AntdConfigProvider,
} from "syngenta-digital-cropwise-react-ui-kit";
import ProductTemplateApi from "../../core/api/productTemplateApi";
import type { ApplicationState } from "../../core/redux/reducers";
import { setSyngentaProductsDetails } from "../../core/redux/reducers/homePageSlice";
import ConstantKey from "../../core/locale/strings.json";
import { sendAmplitudeData } from "../../core/utils/analytics";
import "./moresyngentatiles.less";

interface TileInfo {
  cropwiseLogo: string;
  productLogo: string;
  title: string;
  redirectURL: string;
  poweredByTxt: string;
  amplitudeKey: string;
}
const MoreSyngentaTiles = (): JSX.Element => {
  const uid = useId();
  const history = useNavigate();
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();
  const SyngentaProducts = useSelector(
    (state: ApplicationState) => state.homepage
  );
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  useEffect(() => {
    if (ProfileDataSelector?.defaultLocation?.countryCode) {
      ProductTemplateApi.getTiles(
        ProfileDataSelector?.defaultLocation?.countryCode
      )
        .then((response: { status: number; data: TileInfo[] }) => {
          if (response.status === 200)
            dispatch(setSyngentaProductsDetails(response.data));
        })
        .catch(() => {});
    }
  }, [ProfileDataSelector?.defaultLocation?.countryCode]);

  const onClickTiles = (tileInfo: TileInfo): void => {
    if (tileInfo?.redirectURL === "internal/insightsReport") {
      history("/mapview", {
        state: { anchorId: "/mapview#insightsfromyourfarm" },
      });
    } else {
      window.open(tileInfo.redirectURL, "_block");
    }
    sendAmplitudeData(tileInfo.amplitudeKey);
  };
  return (
    <AntdConfigProvider>
      <div className="grid-story-container">
        <div className="banner-title">
          {translate(ConstantKey.MORE_FROM_SYNGENTA)}
        </div>
        <Row align="top" justify="start">
          {SyngentaProducts?.syngentaProductsDetails?.map(
            (ele: any, i: number) => {
              return (
                <Col
                  flex={1}
                  offset={"0"}
                  order={i}
                  pull="0"
                  push="0"
                  key={uid}
                  onClick={() => {
                    onClickTiles(ele);
                  }}
                >
                  <div className="tiles-syngenta-layout">
                    <div>
                      <span className="tiles-title-text">
                        {ele.poweredByTxt}
                      </span>
                      <span>
                        <img
                          src={ele.cropwiseLogo}
                          className="tiles-title-icon"
                          alt="title icons"
                        />
                      </span>
                    </div>
                    <br />
                    <span>
                      <img src={ele.productLogo} alt="cropwise product" />
                    </span>
                    <br />

                    <span className="tiles-product-description">
                      {translate(ele.title)}
                    </span>
                  </div>
                </Col>
              );
            }
          )}
        </Row>
      </div>
    </AntdConfigProvider>
  );
};
export default MoreSyngentaTiles;
