export const isAccessTokenAvailable = (): boolean =>
  !!localStorage.getItem("access_tokens");

export const setAccessToken = (tokens: any): void => {
  localStorage.setItem("access_tokens", JSON.stringify(tokens));
};

export const getAccessToken = (): string => {
  const accessToken: null | string = localStorage.getItem("access_tokens");
  return accessToken ?? "";
};

export const getMapBoxAccessToken = (): string => {
  return process.env.REACT_APP_MAPBOX_TOKEN ?? "";
};

export const getMetaBlueAPIToken = (): string => {
  return process.env.REACT_APP_METEOBLUE_WEATHER_API_TOKEN ?? "";
};

export const clearAccessToken = (): void => {
  localStorage.removeItem("access_tokens");
};

export const everyNth = (arr: any[], nth: number): any =>
  arr.filter((e, i) => i % nth === nth - 1);

export const getHeaders = (): { common: { Authorization: string } } => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
  },
});

export const getCurrentEnv = (): any => {
  const currentEnv = process.env.REACT_APP_ENV;
  switch (currentEnv) {
    case "local":
      return "local";
    case "dev":
      return "dev";
    case "staging":
      return "staging";
    default:
      return currentEnv;
  }
};

export function osfunction(): string {
  const os = window.navigator.userAgent;
  let finalOs = "";
  if (os.search("Windows") !== -1) {
    finalOs = "Windows";
  } else if (os.search("Mac") !== -1) {
    finalOs = "MacOS";
  }
  return finalOs;
}

export function getCookie(cname: string): string {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (const cookie of ca) {
    const c = cookie.trim();
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(
  name: string,
  value: string,
  expirationDays: number
): void {
  const d = new Date();
  d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function deleteCookie(cname: string): void {
  document.cookie = `${cname}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

/**
 * Make best effort to turn a HTTP error or a runtime exception to meaningful error log message
 */
export function logError(error: any, endpoint: string, method: string): string {
  if (error.status) {
    const summary = `(${error.status} ${error.statusText}): ${error._bodyInit}`;
    const message = `API request ${method.toUpperCase()} ${endpoint} responded with ${summary}`;
    console.error(message);
    return message;
  } else {
    const message = `API request ${method.toUpperCase()} ${endpoint} failed with message "${
      error.message
    }"`;
    console.error(message);
    return message;
  }
}

export function titleCase(str: string): string {
  if (str === "UNITEDSTATES") {
    return "United States";
  }
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export function formatDate(inputTimestamp: string): string {
  const dateObject = new Date(inputTimestamp);
  const formattedDate = dateObject.toLocaleDateString("en-GB", {
    timeZone: "UTC",
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
}

export function toTitleCase(str: string): string {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export function isMobileView(): boolean {
  let isMobile = false;
  const mobileBreakpoint = 1200;
  if (window.innerWidth < mobileBreakpoint) {
    isMobile = true;
  }
  return isMobile;
}

export const setTimeToMidnight = (inputDate: string): string => {
  const date = new Date(inputDate);
  date.setHours(0, 0, 0, 0); // Set the time to midnight (00:00:00.000)
  return date.toISOString().split(".")[0] + "Z"; // Remove milliseconds and ensure UTC format
};
