import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "syngenta-digital-cropwise-react-ui-kit";
import { useTranslation } from "react-i18next";

import {
  METEOBLUE_WEATHER_URL_BASE,
  METEOBLUE_WEATHER_URL_WIDGET_PATH,
  METEOBLUE_WEATHER_URL_CONFIG,
} from "../../core/constants/url";
import ConstantKey from "../../core/locale/strings.json";
import type { ApplicationState } from "../../core/redux/reducers";
import type { MeteoBlueMapDTO } from "../../core/types/mapBox.dto";

import mapFullScreen from "../../assets/icons/map-full-screen.svg";
import closeFullScreen from "../../assets/icons/close_fullscreen.svg";

import "./../mapView/mapView.less";

export const MeteoBlueMap = (props: MeteoBlueMapDTO): JSX.Element => {
  const { t: translate } = useTranslation();
  const profileData = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const homePageState = useSelector(
    (state: ApplicationState) => state.homepage
  );

  const fullScreenMapBtn: any = useRef<HTMLDivElement | null>(null);
  const mapTargetRef = useRef<HTMLDivElement>(null);

  const [currentLng, setCurrentLng] = useState("en");
  const [defaultLocation, setDefaultLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [mapPluginIframeUrl, setMapPluginIframeUrl] = useState("");

  useEffect(() => {
    const currentLanguage = localStorage.getItem("userLanguage");
    if (currentLanguage) {
      setCurrentLng(currentLanguage);
    }
  }, [homePageState?.currentLanguage]);

  useEffect(() => {
    if (
      profileData?.defaultPropertyCoordinates?.lat &&
      profileData?.defaultPropertyCoordinates?.lng
    ) {
      setDefaultLocation(profileData?.defaultPropertyCoordinates);
    }
  }, [profileData?.defaultPropertyCoordinates]);

  useEffect(() => {
    setMapPluginIframeUrl(
      `${METEOBLUE_WEATHER_URL_BASE}${currentLng}${METEOBLUE_WEATHER_URL_WIDGET_PATH}12/${defaultLocation.lat}/${defaultLocation.lng}${METEOBLUE_WEATHER_URL_CONFIG}`
    );
  }, [currentLng, defaultLocation]);

  const changeScreenMode = useCallback(() => {
    if (props.setFullScreenEnabled) {
      props.setFullScreenEnabled(!props.fullScreenEnabled);
      if (mapTargetRef?.current?.scrollIntoView && !props.fullScreenEnabled) {
        mapTargetRef?.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [props.fullScreenEnabled]);

  return (
    <div
      className={
        !props.fullScreenEnabled
          ? "mf-width-100 map-container"
          : "mf-width-100 map-container-full-screen"
      }
      data-testid={
        !props.fullScreenEnabled
          ? "mapbox-container"
          : "mapbox-container-full-screen"
      }
      ref={mapTargetRef}
      key={mapPluginIframeUrl}
    >
      <button
        id="mapboxgl-full-screen-btn"
        data-testid="mapboxgl-full-screen-btn"
        className="mapboxgl-full-screen-btn-top-left"
        ref={fullScreenMapBtn}
        onClick={changeScreenMode}
      >
        <Tooltip
          placement="left"
          title={translate(ConstantKey.MAP_FULL_SCREEN)}
        >
          <img
            src={!props.fullScreenEnabled ? mapFullScreen : closeFullScreen}
            alt="mapboxgl-full-screen-btn-top-left"
          />
        </Tooltip>
      </button>
      {mapPluginIframeUrl && (
        <iframe
          className="meteoblue-map-iframe"
          title="meteoblue-map-iframe"
          key={mapPluginIframeUrl}
          src={mapPluginIframeUrl}
          width="100%"
          sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      )}
    </div>
  );
};

export default MeteoBlueMap;
