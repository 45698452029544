import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ButtonType,
  notification,
  Button,
  DatePicker,
  Modal,
  Tooltip,
} from "syngenta-digital-cropwise-react-ui-kit";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

import ConstantKey from "../../core/locale/strings.json";
import type { ApplicationState } from "../../core/redux/reducers";
import PropertiesApi from "../../core/api/propertyAPI";
import {
  setFieldDetailList,
  setFieldList,
} from "../../core/redux/reducers/fieldDetailsSlice";
import { setAllCrops } from "../../core/redux/reducers/mapviewSlice";
import { sendAmplitudeData } from "../../core/utils/analytics";
import { onManageFieldClick } from "../../core/utils/analytics/constants";
import type { FieldDetailListDto } from "../../core/types/fieldDetails.dto";

import openInNewWindow from "../../assets/icons/openInNewWindow.svg";
import errorOutlineIcon from "../../assets/icons/error_outline.svg";

import "./../mapView/mapView.less";
import "./manageFields.less";

export const ManageFields = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const profileInfoData = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  const mapDetails = useSelector(
    (state: ApplicationState) => state.mapviewdetails
  );

  const farmDetails = useSelector(
    (state: ApplicationState) => state.farmDetails
  );

  const [fieldDetailListLoading, setFieldDetailListLoading] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [serviceFailAlert, setServiceFailAlert] = useState<boolean>(false);
  const [fieldDeletionLoading, setFieldDeletionLoading] =
    useState<boolean>(false);
  const [selectedCropFilterValue, setSelectedCropFilterValue] =
    useState<string>("");
  const [deleteFieldDetails, setDeleteFieldDetails] =
    useState<FieldDetailListDto | null>(null);

  useEffect(() => {
    if (!mapDetails?.allCrops?.length) {
      if (profileInfoData?.defaultLocation?.countryCode) {
        PropertiesApi.getAllCrops(profileInfoData?.defaultLocation?.countryCode)
          .then((res: { data: any }) => {
            dispatch(setAllCrops(res?.data));
          })
          .catch(() => {});
      }
    }
  }, [mapDetails?.allCrops, profileInfoData?.defaultLocation?.countryCode]);

  useEffect(() => {
    if (profileInfoData?.defaultPropertyId) {
      getFieldsByProperty(profileInfoData?.defaultPropertyId);
    }
  }, [profileInfoData?.defaultPropertyId]);

  const getFieldsByProperty = useCallback(
    (
      propertyId: string,
      cropRefId: string = "",
      filterCall: boolean = false
    ) => {
      setServiceFailAlert(false);
      if (!farmDetails?.fieldDetailList?.length || filterCall) {
        dispatch(setFieldDetailList([]));
        setFieldDetailListLoading(true);
        PropertiesApi.getFieldByPropertyId(propertyId, cropRefId)
          .then((response) => {
            if (response?.data?.content?.length) {
              const fieldIdsArray = response?.data?.content.map(
                (eachField: any) => eachField.id
              );
              getFieldDetailList(fieldIdsArray);
              const fieldDataArray = response?.data?.content.map(
                (eachField: any) => {
                  return {
                    id: eachField.id,
                    name: eachField.name,
                    coordinates: eachField.reference_point.coordinates,
                  };
                }
              );
              if (fieldDataArray?.length) {
                setFieldList(fieldDataArray);
              }
            } else {
              setFieldDetailListLoading(false);
            }
          })
          .catch(() => {});
      }
    },
    [farmDetails?.fieldDetailList]
  );

  const getFieldDetailList = useCallback(
    (fieldIds: string[]) => {
      setServiceFailAlert(false);
      const countryCode =
        profileInfoData?.defaultLocation?.countryCode?.toUpperCase();
      if (countryCode) {
        PropertiesApi.fetchFieldData(fieldIds, countryCode)
          .then((response) => {
            if (response?.data?.content?.length) {
              dispatch(setFieldDetailList(response?.data?.content));
            }
            setFieldDetailListLoading(false);
          })
          .catch(() => {
            setServiceFailAlert(true);
            setFieldDetailListLoading(false);
          });
      }
    },
    [profileInfoData?.defaultLocation?.countryCode]
  );

  const redirectToCropwiseFarmSettings = useCallback(() => {
    sendAmplitudeData(onManageFieldClick);
    navigate("/edit-field");
  }, []);

  const redirectToMapViewPage = useCallback(() => {
    navigate("/mapview");
  }, []);

  const redirectToMapViewPageToCreateField = useCallback(() => {
    navigate("/mapview", { state: { enableCreateFieldModeOnLoad: true } });
  }, []);

  const applyCropFilter = useCallback((appliedCropValue: string = "") => {
    setSelectedCropFilterValue(appliedCropValue);
    if (profileInfoData?.defaultPropertyId) {
      getFieldsByProperty(
        profileInfoData?.defaultPropertyId,
        appliedCropValue,
        true
      );
    }
  }, []);

  const openDeleteConfirmationModal = useCallback(
    (fieldDetails: FieldDetailListDto) => {
      setDeleteFieldDetails(fieldDetails);
      setIsModalOpen(true);
    },
    []
  );

  const cancelDeleteField = useCallback(() => {
    setDeleteFieldDetails(null);
    setIsModalOpen(false);
  }, []);

  const triggerDeleteField = useCallback(() => {
    setIsModalOpen(false);
    setFieldDeletionLoading(true);
    if (deleteFieldDetails?.id) {
      PropertiesApi.deleteField(deleteFieldDetails?.id)
        .then((res) => {
          if (profileInfoData?.defaultPropertyId) {
            getFieldsByProperty(
              profileInfoData?.defaultPropertyId,
              selectedCropFilterValue,
              true
            );
          }
        })
        .catch((error) => {
          notification.error({
            message: error?.cause?.data?.errorMessage
              ? error?.cause?.data?.errorMessage
              : `${translate(ConstantKey.SOMETHING_WENT_WRONG)}!`,
            description: "",
            placement: "topRight",
          });
        })
        .finally(() => {
          setIsModalOpen(false);
          setFieldDeletionLoading(false);
          setDeleteFieldDetails(null);
        });
    }
  }, [deleteFieldDetails, selectedCropFilterValue]);

  return (
    <>
      <div className="mf-grid-story-container mf-mb-10 map-view-page">
        <div className="mapview-header mf-mb-45">
          <div className="mapview-header-left">
            <h4
              className="mf-page-title mf-mb-15"
              data-testid="meteoblue-mapview-title"
            >
              {`${translate(ConstantKey.MANAGE_FIELDS_TITLE)} ${
                profileInfoData?.profileData?.firstName
              }'s ${translate(ConstantKey.FARM)}`}
            </h4>
          </div>
          <div className="mapview-header-right">
            <div className="map-layer-farm-btn-sec">
              <Button
                data-testid="view-mapview-page-btn"
                className="map-field-btn mf-mr-10"
                type={ButtonType.outline}
                onClick={redirectToMapViewPage}
              >
                {translate(ConstantKey.VIEW_THE_MAP)}
              </Button>
              <Button
                data-testid="manage-field-btn"
                type={ButtonType.outline}
                className="map-field-btn-outline"
                onClick={redirectToCropwiseFarmSettings}
              >
                <span className="mf-mr-10">
                  {translate(ConstantKey.CROPWISE_FARM_SETTINGS)}
                </span>
                <Tooltip
                  placement="topRight"
                  title={translate(ConstantKey.REDIRECTED_CROPWISE_ENV)}
                >
                  <img
                    src={openInNewWindow}
                    className=""
                    alt="openInNewWindow"
                  />
                </Tooltip>{" "}
              </Button>
            </div>
          </div>
        </div>
        <div className="manage-fields-container">
          <h5
            className="manage-field-sub-title"
            data-testid="meteoblue-mapview-title"
          >
            {translate(ConstantKey.MANAGE_FIELDS_SUB_TITLE)}
          </h5>
          <div className="mf-mb-25">
            <p className="manage-field-title-desc">
              <span>{translate(ConstantKey.MANAGE_FIELDS_TITLE_DESC)}</span>
            </p>
          </div>
          <div className="mf-mb-25">
            {mapDetails?.allCrops?.length && (
              <div className="crop-filter-list">
                <Button
                  type={
                    selectedCropFilterValue === ""
                      ? ButtonType.primary
                      : ButtonType.default
                  }
                  disabled={
                    fieldDetailListLoading ||
                    (!farmDetails?.fieldDetailList?.length &&
                      selectedCropFilterValue === "")
                  }
                  onClick={() => {
                    applyCropFilter("");
                  }}
                  data-testid="all-field-filter"
                >
                  {translate(ConstantKey.ALL_FIELDS)}
                </Button>
                {mapDetails?.allCrops.map((cropDetail: any) => (
                  <Button
                    key={cropDetail.cropRefId}
                    type={
                      selectedCropFilterValue === cropDetail.cropRefId
                        ? ButtonType.primary
                        : ButtonType.default
                    }
                    onClick={() => {
                      applyCropFilter(cropDetail.cropRefId);
                    }}
                    disabled={
                      fieldDetailListLoading ||
                      (!farmDetails?.fieldDetailList?.length &&
                        selectedCropFilterValue === "")
                    }
                  >
                    {cropDetail.crop}
                  </Button>
                ))}
              </div>
            )}
          </div>
          {fieldDetailListLoading && (
            <div>
              <Skeleton.Node
                active={true}
                className="field-loader-skelton mf-mb-10"
              />
              <Skeleton.Node
                active={true}
                className="field-loader-skelton mf-mb-10"
              />
              <Skeleton.Node
                active={true}
                className="field-loader-skelton mf-mb-10"
              />
              <Skeleton.Node
                active={true}
                className="field-loader-skelton mf-mb-10"
              />
            </div>
          )}
          {fieldDeletionLoading && (
            <>
              <div className="mf-loader-overlay"></div>
              <div className="mf-loader"></div>
            </>
          )}
          {!fieldDetailListLoading && (
            <>
              {farmDetails?.fieldDetailList?.length ? (
                <div className="field-list-containers">
                  {farmDetails?.fieldDetailList.map((eachFieldDetail) => (
                    <div
                      key={eachFieldDetail.id}
                      className="field-detail-container mf-mb-15 mf-p-20"
                    >
                      <div className="field-name-container">
                        <h6 className="manage-field-name">
                          {eachFieldDetail.name}
                        </h6>
                        <p>{eachFieldDetail?.crop_info?.crop}</p>
                      </div>
                      <div className="field-edit-container">
                        <div className="field-edit-section-title">
                          {translate(ConstantKey.FIELD_EDIT_SEC_TITLE)}:
                        </div>
                        <div className="field-edit-section">
                          <div className="mf-pr-10">
                            <p className="date-picker-label mf-pt-10">
                              {translate(ConstantKey.PLANTING_DATE)}
                            </p>
                            <DatePicker
                              data-testid="planting-date-input"
                              format={"DD/MM/YYYY"}
                              value={moment(eachFieldDetail?.planting_date)}
                              disabled={true}
                            />
                          </div>
                          {eachFieldDetail?.emergency_date &&
                          profileInfoData?.defaultLocation?.countryCode ===
                            "gb" ? (
                            <div className="mf-pr-10">
                              <p className="date-picker-label mf-pt-10">
                                {translate(ConstantKey.EMERGENCE_DATE)}{" "}
                                <span className="emergence-date-label">
                                  (BYDV)
                                </span>
                              </p>
                              <DatePicker
                                data-testid="emergence-date-input"
                                format={"DD/MM/YYYY"}
                                value={moment(eachFieldDetail?.emergency_date)}
                                disabled={true}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="field-action-container">
                        <Button
                          type={ButtonType.danger}
                          disabled={fieldDeletionLoading}
                          onClick={() => {
                            openDeleteConfirmationModal(eachFieldDetail);
                          }}
                        >
                          {translate(ConstantKey.DELETE_FIELD)}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-field-container">
                  {serviceFailAlert ? (
                    <p>{translate(ConstantKey.SERVICE_IS_UNAVAILABLE)}</p>
                  ) : (
                    <>
                      {selectedCropFilterValue ? (
                        <p>{translate(ConstantKey.NO_FIELDS_FOUND)}</p>
                      ) : (
                        <div className="no-field-created-sec">
                          <p className="error-title">
                            {translate(ConstantKey.FIELD_NOT_AVAILABLE)}
                          </p>
                          <p className="error-desc">
                            {translate(ConstantKey.FIELD_NOT_AVAILABLE_DESC)}
                          </p>
                          <Button
                            data-testid="create-new-field-btn"
                            className="map-field-btn"
                            type={ButtonType.outline}
                            onClick={redirectToMapViewPageToCreateField}
                          >
                            {translate(ConstantKey.CREATE_NEW_FIELD)}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        className="delete-field-confirm-modal"
        title={
          <div className="new-error-modal-container">
            <img
              className="anticon"
              src={errorOutlineIcon}
              alt="Info Warning Icon"
            />
            <span className="error-modal-header-message">
              {translate(ConstantKey.DELETING)} {deleteFieldDetails?.name}
            </span>
          </div>
        }
        open={isModalOpen}
        cancelText={translate(ConstantKey.CANCEL)}
        okText={translate(ConstantKey.DELETE_THIS_FIELD)}
        okType={ButtonType.danger}
        onOk={triggerDeleteField}
        onCancel={cancelDeleteField}
        centered={true}
      >
        <p>{translate(ConstantKey.ARE_YOU_SURE_PERMANENTLY_DELETE_FIELD)}</p>
      </Modal>
    </>
  );
};

export default ManageFields;
